<template>
  <div>
    <h1 class="mt-4 mb-4">Conversor</h1>

    <p>El conversor se encarga de procesar los archivos de video,
      convertirlos en un formato compatible,
      comprobar las solicitudes de peliculas y actualizar la biblioteca del servidor.
      Se ejecuta automáticamente cada 5 minutos.
    </p>

    <div class="row">
      <div class="col-sm-auto">

        <LoadingButton v-if="status.stop" @clicked="activarConversor" :iconProp="'fas fa-check'" :loadingProp="loadingActivar" :classProp="'btn btn-outline-success'" :textProp="'Activar'"></LoadingButton>

        <LoadingButton v-else @clicked="desactivarConversor" :iconProp="'fas fa-times'" :loadingProp="loadingActivar" :classProp="'btn btn-outline-danger'" :textProp="'Desactivar'"></LoadingButton>

      </div>

      <div class="col-sm-auto">
        <LoadingButton @clicked="limpiarLog" :iconProp="'fas fa-brush'" :loadingProp="loadingLimpiarLog" :classProp="'btn btn-outline-info'" :textProp="'Limpiar log'"></LoadingButton>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-auto border m-3 shadow bg-light">
        <div class="card-body text-center">
          <h5><i class="fas fa-thermometer-half"></i> Estado</h5>
          <hr>
          <div>

            <p v-if="status.running"><span class="badge badge-success">Funcionando</span></p>

            <p v-else><span class="badge badge-danger">Parado</span></p>

            <p v-show="status.running && status.stop">El conversor se detendrá cuando termine de convertir el archivo actual.</p>

          </div>
        </div>
      </div>

      <div class="col-sm-auto border m-3 shadow bg-light">
        <div class="card-body text-center">
          <h5><i class="fas fa-toggle-on"></i> Inicio</h5>
          <hr>

          <p v-if="status.stop"><span class="badge badge-danger">Desactivado</span></p>

          <p v-else><span class="badge badge-success">Activado</span></p>

        </div>
      </div>
    </div>

    <hr>

    <div style="white-space: pre-wrap;">
      {{ log }}
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../config'
  import Swal from 'sweetalert2'
  import LoadingButton from '../components/LoadingButton.vue'

  export default {
    data() {
      return {
        loadingStats: true,
        errorStats: false,
        status: {
          running: null,
          stop: null
        },
        loadingActivar: false,
        loadingLimpiarLog: false,
        log: null
      }
    },
    components: {
      LoadingButton
    },
    methods: {
      async getStatus() {
        try {
          this.loadingStats = true
          const res = await axios.get(config.apiUrl + '/conversor/estado')
          this.loadingStats = false

          this.status = res.data
        } catch (e) {
          this.loadingStats = false
          this.errorStats = true
        }
      },
      async getLog() {
        try {
          const res = await axios.get(config.apiUrl + '/conversor/log')

          this.log = res.data
        } catch (e) {
          this.log = e.response.data
        }

        setInterval(async function() {
          try {
            const res = await axios.get(config.apiUrl + '/conversor/log')

            this.log = res.data
          } catch (e) {
            this.log = e.response.data
          }
        }.bind(this), 5000)
      },
      async activarConversor() {
        try {
          this.loadingActivar = true
          const res = await axios.post(config.apiUrl + '/conversor/activar')
          this.loadingActivar = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.getStatus()
        } catch (e) {
          this.loadingActivar = false
          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      async desactivarConversor() {
        try {
          this.loadingActivar = true
          const res = await axios.post(config.apiUrl + '/conversor/desactivar')
          this.loadingActivar = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.getStatus()
        } catch (e) {
          this.loadingActivar = false
          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      async limpiarLog() {
        try {
          this.loadingLimpiarLog = true
          const res = await axios.post(config.apiUrl + '/conversor/limpiarLog')
          this.loadingLimpiarLog = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        } catch (e) {
          this.loadingLimpiarLog = false
          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    },
    mounted() {
      this.$store.commit('setPanelLayout')
      this.getStatus()
      //this.getLog()
    }
  }
</script>
